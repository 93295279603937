// Shadows offset, blur, spread and color

$box-shadow-0: none !default;
$box-shadow-1: 0 0 2px 0 rgb(0 0 0 / 0.07), 0 1px 1px 0 rgb(0 0 0 / 0.04) !default;
$box-shadow-2: 0 0 3px 0 rgb(0 0 0 / 0.07), 0 2px 2px 0 rgb(0 0 0 / 0.04) !default;
$box-shadow-4: 0 2px 15px -3px rgb(0 0 0 / 0.07), 0 10px 20px -2px rgb(0 0 0 / 0.04) !default;

// Forms
//
// Material styling for outline forms border, label, size

$input-padding-top: 0.33em !default;
$input-padding-bottom: $input-padding-top !default;
$input-padding-left: 0.75em !default;
$input-padding-right: $input-padding-left !default;
$input-transition: all 0.2s linear !default;
$form-label-left: 0.75rem !default;
$form-label-padding-top: 0.37rem !default;
$form-label-transition: all 0.2s ease-out !default;
$form-label-color: rgba(0, 0, 0, 0.6) !default;
$input-focus-active-label-transform: translateY(-1rem) translateY(0.1rem) scale(0.8) !default;
$input-focus-label-color: $primary !default;
$input-focus-border-width: 0.125rem !default;
$input-focus-border-color: $primary !default;
$input-disabled-background-color: #e9ecef !default;
$input-padding-left-lg: 0.75em !default;
$input-padding-right-lg: $input-padding-left-lg !default;
$input-font-size-lg: 1rem !default;
$input-line-height-lg: 2.15 !default;
$input-focus-active-label-transform-lg: translateY(-1.25rem) translateY(0.1rem) scale(0.8) !default;
$input-padding-left-sm: 0.99em !default;
$input-padding-right-sm: $input-padding-left-sm !default;
$input-padding-top-sm: 0.43em !default;
$input-padding-bottom-sm: 0.35em !default;
$input-font-size-sm: 0.775rem !default;
$input-line-height-sm: 1.6 !default;
$input-focus-active-label-transform-sm: translateY(-0.85rem) translateY(0.1rem) scale(0.8) !default;
$form-notch-div-border-color: #bdbdbd !default;
$form-notch-leading-width: 0.5rem !default;
$form-notch-leading-border-radius: 0.25rem !default;
$form-notch-middle-max-width: 1rem !default;
$form-notch-trailing-border-radius: $form-notch-leading-border-radius !default;
$form-label-padding-top-lg: 0.7rem !default;
$form-label-padding-top-sm: 0.33rem !default;
$form-label-font-size-sm: 0.775rem !default;
$form-white-input-color: $white !default;
$form-white-label-color: $gray-light !default;
$form-white-notch-div-border-color: $gray-light !default;
$form-white-input-focus-label-color: $white !default;
$form-white-input-focus-border-color: $white !default;
$form-white-placeholder-color: rgba(255, 255, 255, 0.7) !default;
$form-white-disabled-bgc: rgba(255, 255, 255, 0.45) !default;
$form-check-input-margin-left: 1.79rem !default;
$form-check-input-radio-margin-left: 1.85rem !default;
$form-check-input-width-md: 1.125rem !default;
$form-check-input-height: $form-check-input-width-md !default;
$form-check-input-background-color: $white !default;
$form-check-input-border-width: 0.125rem !default;
$form-check-input-border-color: rgba(0, 0, 0, 0.25) !default;
$form-check-input-before-box-shadow: 0 0 0 13px transparent !default;
$form-check-input-before-border-radius: 50% !default;
$form-check-input-before-width: 0.875rem !default;
$form-check-input-before-height: $form-check-input-before-width !default;
$form-check-input-before-transform: scale(0) !default;
$form-check-input-hover-before-opacity: 0.04 !default;
$form-check-input-hover-before-box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.6) !default;
$form-check-input-focus-border-color: $form-check-input-border-color !default;
$form-check-input-focus-transition: border-color 0.2s !default;
$form-check-input-focus-before-opacity: 0.12 !default;
$form-check-input-focus-before-box-shadow: $form-check-input-hover-before-box-shadow !default;
$form-check-input-focus-before-transform: scale(1) !default;
$form-check-input-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;
$form-check-input-checked-border-color: $primary !default;
$form-check-input-checked-before-opacity: 0.16 !default;
$form-check-input-checked-focus-border-color: $primary !default;
$form-check-input-checked-focus-before-box-shadow: 0 0 0 13px $primary !default;
$form-check-input-checked-focus-before-transform: $form-check-input-focus-before-transform !default;
$form-check-input-checked-focus-before-transition: $form-check-input-focus-before-transition !default;
$form-check-input-checkbox-border-radius: 0.25rem !default;
$form-check-input-checkbox-focus-after-width: 0.875rem !default;
$form-check-input-checkbox-focus-after-height: $form-check-input-checkbox-focus-after-width !default;
$form-check-input-checkbox-focus-after-background-color: $white !default;
$form-check-input-checkbox-checked-background-color: $primary !default;
$form-check-input-checkbox-checked-focus-background-color: $primary !default;
$form-check-input-checkbox-checked-after-transform: rotate(45deg) !default;
$form-check-input-checkbox-checked-after-border-width: 0.125rem !default;
$form-check-input-checkbox-checked-after-border-color: $white !default;
$form-check-input-checkbox-checked-after-width: 0.375rem !default;
$form-check-input-checkbox-checked-after-height: 0.8125rem !default;
$form-check-input-checkbox-checked-after-margin-left: 0.25rem !default;
$form-check-input-checkbox-checked-after-margin-top: -1px !default;
$form-check-input-indeterminate-focus-before-box-shadow: 0 0 0 13px $primary !default;
$form-check-input-indeterminate-checked-background-color: $primary !default;
$form-check-input-indeterminate-checked-after-transform: rotate(90deg) !default;
$form-check-input-indeterminate-checked-after-border-width: 0.125rem !default;
$form-check-input-indeterminate-checked-after-border-color: $white !default;
$form-check-input-indeterminate-checked-after-width: 0.125rem !default;
$form-check-input-indeterminate-checked-after-height: 0.875rem !default;
$form-check-input-indeterminate-checked-after-margin-left: 0.375rem !default;
$form-check-input-indeterminate-focus-background-color: $primary !default;
$form-check-input-indeterminate-focus-border-color: $primary !default;
$form-check-input-indeterminate-border-color: $gray-600 !default;
$form-check-input-radio-border-radius: 50% !default;
$form-check-input-radio-width: 1.25rem !default;
$form-check-input-radio-height: $form-check-input-radio-width !default;
$form-check-input-radio-before-width: 1rem !default;
$form-check-input-radio-before-height: $form-check-input-radio-before-width !default;
$form-check-input-radio-after-width: $form-check-input-radio-before-width !default;
$form-check-input-radio-after-height: $form-check-input-radio-after-width !default;
$form-check-input-radio-after-border-radius: $form-check-input-radio-border-radius !default;
$form-check-input-radio-after-background-color: $white !default;
$form-check-input-radio-checked-background-color: $white !default;
$form-check-input-radio-checked-after-border-radius: $form-check-input-radio-border-radius !default;
$form-check-input-radio-checked-after-width: 0.625rem !default;
$form-check-input-radio-checked-after-height: $form-check-input-radio-checked-after-width !default;
$form-check-input-radio-checked-after-border-color: $primary !default;
$form-check-input-radio-checked-after-background-color: $primary !default;
$form-check-input-radio-checked-after-margin-top: 0.1875rem !default;
$form-check-input-radio-checked-after-margin-left: 0.1875rem !default;
$form-check-input-radio-checked-after-transition: border-color !default;
$form-check-input-radio-checked-focus-background-color: $white !default;
$form-switch-padding-left: 1.85rem !default;
$form-switch-form-check-input-border-radius: 0.4375rem !default;
$form-switch-form-check-input-width: 2rem !default;
$form-switch-form-check-input-height: 0.875rem !default;
$form-switch-form-check-input-background-color: rgba(0, 0, 0, 0.25) !default;
$form-switch-form-check-input-margin-top: 0.35rem !default;
$form-switch-form-check-input-margin-right: 0.7rem !default;
$form-switch-form-check-input-after-border-radius: 50% !default;
$form-switch-form-check-input-after-width: 1.25rem !default;
$form-switch-form-check-input-after-height: $form-switch-form-check-input-after-width !default;
$form-switch-form-check-input-after-background-color: $white !default;
$form-switch-form-check-input-after-margin-top: -0.1875rem !default;
$form-switch-form-check-input-after-box-shadow: $box-shadow-2 !default;
$form-switch-form-check-input-after-transition: background-color 0.2s, transform 0.2s !default;
$form-switch-form-check-input-focus-before-box-shadow: 3px -1px 0 13px rgba(0, 0, 0, 0.6) !default;
$form-switch-form-check-input-focus-before-transform: scale(1) !default;
$form-switch-form-check-input-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;
$form-switch-form-check-input-focus-after-border-radius: $form-switch-form-check-input-after-border-radius !default;
$form-switch-form-check-input-focus-after-width: 1.25rem !default;
$form-switch-form-check-input-focus-after-height: $form-switch-form-check-input-focus-after-width !default;
$form-switch-form-check-input-checked-focus-before-margin-left: 1.0625rem !default;
$form-switch-form-check-input-checked-focus-before-box-shadow: 3px -1px 0 13px $primary !default;
$form-switch-form-check-input-checked-focus-before-transform: scale(1) !default;
$form-switch-form-check-input-checked-focus-before-transition: box-shadow 0.2s, transform 0.2s !default;
$form-switch-form-check-input-checked-checkbox-after-border-radius: 50% !default;
$form-switch-form-check-input-checked-checkbox-after-width: 1.25rem !default;
$form-switch-form-check-input-checked-checkbox-after-height: 1.25rem !default;
$form-switch-form-check-input-checked-checkbox-after-background-color: $primary !default;
$form-switch-form-check-input-checked-checkbox-after-margin-top: -3px !default;
$form-switch-form-check-input-checked-checkbox-after-margin-left: 1.0625rem !default;
$form-switch-form-check-input-checked-checkbox-after-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !default;
$form-switch-form-check-input-checked-checkbox-after-transition: background-color 0.2s,
    transform 0.2s !default;
$form-file-height: calc(1.79rem + 0.33rem + 2px) !default;
$form-file-label-focus-border-color: $primary !default;
$form-file-label-focus-transition: all 0.2s linear !default;
$form-file-label-focus-text-border-width: 2px 1px 2px 2px !default;
$form-file-label-focus-button-border-width: 2px 2px 2px 1px !default;
$form-file-label-disabled-background-color: #e9ecef !default;
$form-file-label-height: $form-file-height !default;
$form-file-label-border-color: #bdbdbd !default;
$form-file-label-border-radius: 0.2rem !default;
$form-file-text-padding-y: 0.33rem !default;
$form-file-text-border-color: #bdbdbd !default;
$form-file-button-line-height: 1.5 !default;
$form-file-button-background-color: #fff !default;
$form-file-button-border-color: $form-file-text-border-color !default;
$form-file-sm-height: calc(1.33rem + 0.33rem + 2px) !default;
$form-file-sm-font-size: 0.775rem !default;
$form-file-sm-line-height: 1.5 !default;
$form-file-sm-padding-y: $form-file-text-padding-y !default;
$form-file-lg-height: calc(2.315rem + 0.33rem + 2px) !default;
$form-file-lg-font-size: 1rem !default;
$form-file-lg-line-height: 2.15 !default;
$form-file-lg-padding-y: $form-file-text-padding-y !default;
$form-range-webkit-slider-thumb-margin-top: -6px !default;
$form-range-webkit-slider-runnable-track-height: 4px !default;
$input-group-height: calc(1.75rem + 0.33rem + 2px) !default;
$input-group-min-height: $input-group-height !default;
$input-group-padding-y: 0.27rem !default;
$input-group-transition: all 0.2s linear !default;
$input-group-focus-transition: $input-group-transition !default;
$input-group-focus-border-color: $primary !default;
$input-group-focus-box-shadow: inset 0 0 0 1px $primary !default;
$input-group-text-padding-y: 0.26rem !default;
$input-group-lg-height: calc(2.315rem + 0.33rem + 2px) !default;
$input-group-lg-font-size: 1rem !default;
$input-group-lg-padding-y: 0.33rem !default;
$input-group-lg-text-font-size: $input-group-lg-font-size !default;
$input-group-sm-height: calc(1.33rem + 0.33rem + 2px) !default;
$input-group-sm-min-height: $input-group-sm-height !default;
$input-group-sm-font-size: 0.775rem !default;
$input-group-sm-padding-y: $input-group-lg-padding-y !default;
$input-group-sm-text-font-size: $input-group-sm-font-size !default;
$input-group-sm-text-line-height: 1.5 !default;
$input-group-form-outline-border-left-width: 1px !default;
$input-group-form-outline-border-left-color: #bdbdbd !default;

// Select
$form-select-transition: all 0.2s linear !default;
$form-select-focus-border-color: $primary !default;
$form-select-focus-border-box-shadow: inset 0 0 0 1px $primary !default;

// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-color: $body-color !default;
$dropdown-margin-top: 0.125rem !default;
$dropdown-box-shadow: $box-shadow-4 !default;
$dropdown-font-size: 0.875rem !default;
$dropdown-item-border-radius: 0.5rem !default;
$dropdown-item-padding-y: 0.5rem !default;
$dropdown-item-padding-x: 1rem !default;
$dropdown-border-radius: 0.5rem !default;
$dropdown-divider-bg: hsl(0, 0%, 96%) !default;
$dropdown-divider-border-top-width: 2px;

$dropdown-state-color: #16181b !default;
$dropdown-state-background-color: #eee !default;

$dropdown-menu-animated-animation-duration: 0.55s !default;
$dropdown-menu-animated-animation-timing-function: ease !default;

$dropdown-animation-duration: 1s !default;
// scss-docs-end dropdown-variables