.accordion {
    --#{$prefix}accordion-active-bg: #{$body-bg-gray};
    --#{$prefix}accordion-btn-icon-width: 1rem;
    --#{$prefix}accordion-btn-icon-transform: translateX(-6px) rotate(180deg);

    margin-bottom: 4.5rem;
}

.accordion-item {
    border-top: 1px solid $gray-dark;
    &:not(:first-of-type) {
        border-top: 1px solid $gray-dark;
    }
    .accordion-button {
        font-family: $font-family-sans-serif;
        font-size: 1.5rem;
        font-weight: $font-weight-normal;
        &::after {
            transform: translateX(-6px)
        }
        &:not(.collapsed)::after {
            transform: var(--#{$prefix}accordion-btn-icon-transform)
        }
        &:focus {
          background: linear-gradient(180deg, $gray-lighter, transparent, transparent);
        }
    }
}