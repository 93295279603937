.search-suggestions {
    position: relative;
    z-index: 999;
    display: none;

    .suggest-results {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 450px;
        max-height: 320px;
        border: 1px solid $gray-light;
        padding-left: 0;
        overflow: auto;
        background-color: $white;
        list-style: none;
        box-shadow: 4px 8px 8px rgb(0 0 0 / 0.3);

        .suggest-group-header {
            border-bottom: 1px solid $gray-lighter;
            padding: 0.25rem 0.5rem;
            background-color: $gray-light;
        }

        .suggest-item {
            border-bottom: 1px solid $gray-lighter;
            font-size: 0.875rem;

            a {
                display: block;
                padding: 0.25rem 0.5rem;
                text-decoration: none;
                cursor: pointer;

                &:hover,&:focus,&.focus {
                    background-color: $gray-lighter;
                }

                span.location {
                    display: block;
                    color: $gray-darker;
                    font-size: 0.75rem;
                }
            }
        }
    }
}
