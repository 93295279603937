.container.content-breadcrumb {
    margin-top: 4rem;
    @include media-breakpoint-up(md) {
        margin-top: 5rem;
    }
}

ol.breadcrumb {
    margin-top: 3rem;

    .breadcrumb-item {
        &+.breadcrumb-item::before {
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: 'iconkuoni';
            font-size: 0.75rem;
            padding-top: 6px;
        }
        &.active {
            a {
                font-weight: $font-weight-bold;
            }
        }
    }

    a {
        color: $body-color;
    }
}
