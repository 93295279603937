@import '../bootstrap/variables';

@import '../../node_modules/bootstrap/scss/mixins/banner';
@include bsBanner('');
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';

.megamenu-overlay {
    display: none;
    position: absolute;
    top: 140px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0,0,0,0.4)
}

.megamenu-container {
    position: absolute;
    top: 145px;
    left: 0;
    right: 0;
    z-index: 100;
    .megamenu-item {
        padding: 1.5rem 1.5rem 0.5rem;
        background-color: #fff;
        box-shadow: 5px 5px 10px -5px rgba(0,0,0,0.5);
        border-radius: $border-radius;
        img {
            border-radius: $border-radius;
        }
    }
    .collapsing {
        transition: none;
        display: none;
    }

    h4 {
        font-size: 1rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }
    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            padding: 0 0 0.125rem;
            a {
                display: block;
                position: relative;
                color: $body-color;
                padding: 0.25rem 0;
                border-bottom: 1px solid transparent;
                &:hover {
                    color: $primary;
                    border-bottom: 1px solid $gray-lighter;
                    &::after {
                        // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
                        font-family: iconkuoni;
                        color: $primary;
                        content: '\e911';
                        position: absolute;
                        right: 0px;
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .megamenu-overlay,
    .megamenu-container {
        display: none !important;
    }
}

body.alert-bar-shown {
    .megamenu-overlay {
        top: 180px;
    }
    .megamenu-container {
        top: 185px;
    }
}
