/* stylelint-disable no-descending-specificity */

// fix for checkbox checked styles in FireFox, resolves problem
// with check mark position
.select-dropdown .form-check-input label {
    display: block;
}

select.select-initialized {
    display: none !important;
}

.select-arrow {
    position: absolute;
    top: $form-outline-select-arrow-top;
    right: $form-outline-select-arrow-right;
    color: $form-outline-select-arrow-color;
    font-size: $form-outline-select-arrow-font-size;
    text-align: center;

    &::before {
        content: '\2BC6';
    }
}

.was-validated .form-control:valid ~ .select-arrow {
    color: $form-outline-select-valid-color;
}

.was-validated .form-control:invalid ~ .select-arrow {
    color: $form-outline-select-invalid-color;
}

.select-clear-btn {
    position: absolute;
    top: $form-outline-select-clear-btn-top;
    right: $form-outline-select-clear-btn-right;
    color: $form-outline-select-clear-btn-color;
    font-size: $form-outline-select-clear-btn-font-size;
    cursor: pointer;

    &:focus {
        color: $form-outline-select-clear-btn-focus-color;
        outline: none;
    }
}

.form-control-sm ~ .select-clear-btn {
    top: $form-outline-select-sm-clear-btn-top;
    font-size: $form-outline-select-sm-clear-btn-font-size;
}

.form-control-lg ~ .select-clear-btn {
    top: $form-outline-select-lg-clear-btn-top;
}

.select-dropdown-container {
    z-index: $form-outline-select-dropdown-container-z-index;
}

.select-dropdown {
    position: relative;
    min-width: $form-outline-select-dropdown-min-width;
    margin: 0;
    background-color: $form-outline-select-dropdown-bgc;
    outline: 0;
    box-shadow: $form-outline-select-dropdown-box-shadow;
    transform: $form-outline-select-dropdown-transform;
    opacity: 0;
    transition: $form-outline-select-dropdown-transition;

    &.open {
        transform: $form-outline-select-dropdown-open-transform;
        opacity: 1;
    }
}

.select-dropdown > .input-group {
    padding: $form-outline-select-dropdown-input-group-padding;
}

.select-label {
    max-width: $form-outline-select-label-max-width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // tmp
    display: block;

    &.active {
        transform: $form-outline-select-label-active-transform;
    }
}

.form-control-lg ~ .select-label.active {
    transform: $form-outline-select-lg-label-active-transform;
}

.form-control-sm ~ .select-label.active {
    transform: $form-outline-select-sm-label-active-transform;
}

.form-outline .select-label.active ~ .form-notch .form-notch-middle {
    border-top: 1px solid transparent !important;
    border-right: none;
    border-left: none;
}

.select-input {
    cursor: pointer;

    &[disabled] {
        cursor: default;
    }
}

.select-input.focused {
    color: $form-outline-select-input-focused-color;
    outline: 0;

    & ~ .select-label {
        color: $form-outline-select-label-color;
    }

    &::placeholder {
        opacity: 1;
    }

    & ~ .form-notch .form-notch-leading {
        border-color: $form-outline-select-notch-border-color;
        box-shadow: -1px 0 0 0 $form-outline-select-notch-border-color,
            0 1px 0 0 $form-outline-select-notch-border-color,
            0 -1px 0 0 $form-outline-select-notch-border-color;
    }

    & ~ .form-notch .form-notch-middle {
        border-color: $form-outline-select-notch-border-color;
        border-top: 1px solid transparent;
        box-shadow: 0 1px 0 0 $form-outline-select-notch-border-color;
    }

    & ~ .form-notch .form-notch-trailing {
        border-color: $form-outline-select-notch-border-color;
        box-shadow: 1px 0 0 0 $form-outline-select-notch-border-color,
            0 -1px 0 0 $form-outline-select-notch-border-color,
            0 1px 0 0 $form-outline-select-notch-border-color;
    }
}

.select-input.focused ~ .select-arrow {
    color: $form-outline-select-input-focused-arrow-color;
}

.form-white .select-input:focus ~ .select-arrow {
    color: $form-outline-select-white-focus-arrow-color;
}

.form-white .select-arrow {
    color: $form-outline-select-white-arrow-color;
}

.form-white .select-clear-btn {
    color: $form-outline-select-white-clear-btn;
}

.form-control-sm ~ .select-arrow {
    top: $form-outline-select-sm-arrow-top;
}

.form-control-lg ~ .select-arrow {
    top: $form-outline-select-lg-arrow-top;
}

.select-options-wrapper {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: $form-outline-select-options-wrapper-scrollbar-width;
        height: $form-outline-select-options-wrapper-scrollbar-height;
    }

    /*
    &::-webkit-scrollbar-button {
        &:start:decrement,
        &:end:increment {
            display: block;
            height: 0;
            background-color: transparent;
        }
    }
    */

    &::-webkit-scrollbar-track-piece {
        border-radius: 0;
        border-bottom-right-radius: $form-outline-select-options-wrapper-scrollbar-border-bottom-right-radius;
        border-bottom-left-radius: $form-outline-select-options-wrapper-scrollbar-border-bottom-left-radius;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb:vertical {
        height: $form-outline-select-options-wrapper-scrollbar-thumb-height;
        border-radius: $form-outline-select-options-wrapper-scrollbar-thumb-border-radius;
        background-color: $form-outline-select-options-wrapper-scrollbar-thumb-bgc;
    }
}

.select-options-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.select-option-group-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-right: $form-outline-select-option-group-label-pr;
    padding-left: $form-outline-select-option-group-label-pl;
    overflow: hidden;
    color: $form-outline-select-option-group-label-color;
    background-color: transparent;
    font-size: $form-outline-select-option-group-label-font-size;
    font-weight: $form-outline-select-option-group-label-font-weight;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
}

.select-option-group > .select-option {
    padding-left: $form-outline-select-option-group-select-option-pl;
}

.select-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: $form-outline-select-option-pr;
    padding-left: $form-outline-select-option-pl;
    overflow: hidden;
    color: $form-outline-select-option-color;
    background-color: transparent;
    font-size: $form-outline-select-option-font-size;
    font-weight: $form-outline-select-option-font-weight;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    user-select: none;

    &:hover:not(.disabled) {
        background-color: $form-outline-select-option-hover-not-disabled-bgc;
    }

    &.active {
        background-color: $form-outline-select-option-active-bgc;
    }

    &.selected.active {
        background-color: $form-outline-select-option-selected-active-bgc;
    }

    &.selected.disabled {
        color: $form-outline-select-option-selected-disabled-color;
        background-color: transparent;
        cursor: default;
    }

    &.selected {
        background-color: $form-outline-select-option-selected-bgc;
    }

    &.disabled {
        color: $form-outline-select-option-disabled-color;
        cursor: default;
    }
}

.select-option-text {
    & .form-check-input {
        margin-right: $form-outline-select-option-text-form-check-input-mr;
    }
}

.select-option-secondary-text {
    display: block;
    color: $form-outline-select-option-secondary-text-color;
    font-size: $form-outline-select-option-secondary-text-font-size;
    line-height: normal;
}

.select-option-icon {
    width: $form-outline-select-option-icon-width;
    height: $form-outline-select-option-icon-height;
}

.select-custom-content {
    padding: $form-outline-select-custom-content-padding;
}

.select-no-results {
    display: flex;
    align-items: center;
    padding-right: $form-outline-select-no-results-pr;
    padding-left: $form-outline-select-no-results-pl;
}

// Select white
.form-white .select-input.focused ~ .select-arrow {
    color: $form-outline-select-white-arrow;
}

// Fake label
.form-outline .form-control ~ .form-label.select-fake-value,
.form-outline .form-control:focus ~ .form-label.select-fake-value,
.form-outline .form-control.active ~ .form-label.select-fake-value {
    display: none;
    transform: none;

    &.active {
        display: block;
    }
}
