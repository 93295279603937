.card {
    a {
        color: $body-color;
    }

    h5 {
        font-size: 1.75rem;
        font-weight: $font-weight-normal;
        line-height: 2.1875rem;
    }

    .card-footer {
        border-top: none;
    }

    &.card-highlight {
        .card-body {
            background-color: $blue;
            color: #fff;

            a {
                color: #fff;
            }
        }
    }
}
