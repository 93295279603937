.header-media {
    position: relative;

    img {
        position: relative;
        width: 100%;
        height: auto;
    }

    .text-overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1rem;
        z-index: 4;

        .overlay-box {
            display: inline-block;
            max-width: 100%;
            border-radius: 12px;
            color: $white;
            background-color: $primary-light;
            box-shadow: 8px 8px 0 0 #be1e25;
            padding: 1.25rem 1.5rem;
        }

        .title,
        .text-description {
            font-size: 1.2rem;
            line-height: 1.25;
            display: inline-block;
            max-width: 100%;
            overflow-wrap: break-word;

            i {
                font-size: 1rem;
                display: none;
            }
        }

        .title {
            font-family: $headings-font-family;
            font-size: 1.5rem;
        }

        @include media-breakpoint-up(sm) {
            bottom: 2rem;
        }

        @include media-breakpoint-up(md) {
            bottom: 2rem;
        }

        @include media-breakpoint-up(lg) {
            top: 12rem;
            bottom: unset;
            .title {
                font-size: 2.125rem;
            }
            .text-description {
                font-size: 1.375rem;
            }
        }

        @include media-breakpoint-up(xl) {
            top: 15rem;
        }

        @media (min-width: 1500px) {
            top: 20rem;
        }
    }

    a {
        .text-overlay {
            .text-description {
                i {
                    display: inline;
                }
            }
        }
    }

    picture {
        position: relative;
        display: block;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0,0,0,0.48),rgba(0,0,0,0),rgba(0,0,0,0));
            z-index: 1;
        }
    }
}

.content-main {
    .header-media {
        margin-bottom: 4.5rem;
    }
}

