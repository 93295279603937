/* noto-sans-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/noto-sans-v36-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* Clarendon Std Bold */
@font-face {
    font-display: swap;
    font-family: 'Clarendon';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/clarendonstdbold.woff2') format('woff2'), url('../fonts/clarendonstdbold.woff') format('woff');
}

//body {
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//}
