.frame-mb-0 {
    margin-bottom: 0;
}

.mb-small,
.frame-mb-small {
    margin-bottom: 2rem;
}

.mb-medium,
.frame-mb-medium {
    margin-bottom: 4.5rem;
}

.mb-large,
.frame-mb-large {
    margin-bottom: 6rem;
}

.frame-type-text {
    &.frame-layout-1 {
        --bs-link-color-rgb: 255, 255, 255;
        color: #fff;
        padding: 1.25rem;
        background-color: $secondary-light;
        border-radius: $border-radius-big;
        margin-bottom: 2rem;

        p:last-of-type {
            margin-bottom: 0;
        }

        .btn.btn-primary {
            --#{$prefix}btn-bg: #{$secondary};
            --#{$prefix}btn-border-color: #{$secondary};
            --#{$prefix}btn-hover-bg: #{$secondary};
            --#{$prefix}btn-hover-border-color: #{$secondary};
            --#{$prefix}btn-active-bg: #{$secondary};
            --#{$prefix}btn-active-border-color: #{$secondary};
        }
    }
}

.frame-type-image {
    .ce-row {
        @extend .row;
    }

    .image-embed-item {
        max-width: 100%;
        height: auto;
        border-radius: $border-radius-big;
    }

    .ce-gallery {
        a {
            &:focus-visible {
                display: block;
                border-radius: 8px;
                outline: 0;
                box-shadow: $card-focus-box-shadow;
            }

        }
    }

    .ce-gallery[data-ce-columns='4'] {
        .ce-column {
            @extend .col-12;
            @extend .col-sm-6;
            @extend .col-lg-3;
        }
    }

    .ce-gallery[data-ce-columns='3'] {
        .ce-column {
            @extend .col-12;
            @extend .col-sm-6;
            @extend .col-lg-4;
        }
    }

    .ce-gallery[data-ce-columns='2'] {
        .ce-column {
            @extend .col-12;
            @extend .col-sm-6;
        }
    }

    .card {
        --#{$prefix}card-border-radius: #{$border-radius-big};

        &.card-image {
            margin-bottom: 3rem;

            .image-embed-item {
                border-top-left-radius: $border-radius-big;
                border-top-right-radius: $border-radius-big;
            }

            .card-body {
                padding: 1rem 0;

                p {
                    margin-bottom: 0.375rem;
                }

                h5 {
                    font-size: 1.5rem;
                }
            }
            &.card-image-teaser {
                .image-embed-item {
                    border-bottom-left-radius: unset;
                    border-bottom-right-radius: unset;
                }
                .card-body {
                    padding: 1.5rem 1.5rem;
                }
                h5 {
                    margin-bottom: 0;
                }
                p {
                    margin-bottom: 0;
                    &.small {
                        margin-bottom: 0.25rem;
                    }
                }
            }

            &.card-image-overlay {
                .image-embed-item {
                    border-bottom-left-radius: $border-radius-big;
                    border-bottom-right-radius: $border-radius-big;
                }

                picture {
                    position: relative;
                    display: block;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.36));
                        z-index: 1;
                        border-radius: $border-radius-big;
                    }
                }

                .card-body {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    padding: 0;

                    .content-overlay {
                        padding: 0 1.5rem 1.5rem;
                        color: #fff;
                    }

                    h5 {
                        font-size: 1.75rem;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.frame-type-textmedia {
    .image-embed-item {
        max-width: 100%;
        height: auto;
    }

    .video-embed {
        @extend .ratio;
        @extend .ratio-16x9;

        .no-consent-info {
            display: none;
        }

        &.no-consent {
            .no-consent-info {
                display: block;
                font-style: italic;
                text-align: center;
                padding: 1rem;
                background-color: #f1f1f1;
                margin: 2rem 0;
                a {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
    .ce-textpic {
        @extend .row;
        margin-bottom: 4.5rem;

        .ce-gallery,
        .ce-bodytext {
            @extend .col-12;
        }

        .ce-bodytext {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &.ce-intext {
            position: relative;

            .ce-gallery {
                figure {
                    margin-bottom: 0;

                    .image-embed-item {
                        width: 100%;
                        border-top-left-radius: $border-radius-big;
                        border-top-right-radius: $border-radius-big;

                        @include media-breakpoint-up(lg) {
                            border-radius: $border-radius-big;
                        }
                    }
                }
            }
            .ce-bodytext {

                .ce-bodytext-inner {
                    padding: 2.25rem 2.25rem 1.25rem;
                    background-color: $white;
                    border-bottom-left-radius: $border-radius-big;
                    border-bottom-right-radius: $border-radius-big;

                    p {
                        a {
                            &::after {
                                content: '';
                                display: inline-block;
                                width: 20px;
                                height: 14px;
                                margin-left: 0.5rem;
                                background-image: url('data:image/svg+xml;utf8,%3Csvg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z" fill="%23BD1E24"/%3E%3C/svg%3E');
                            }
                            &.btn {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        border-radius: $border-radius-big;
                    }
                }

                p {
                    a {
                        font-weight: $font-weight-bold;
                    }
                }
            }


            &.ce-left {
                .ce-gallery {
                    @extend .col-lg-6;

                    @include media-breakpoint-up(lg) {
                        padding-left: calc(var(--bs-gutter-x)* .5);
                        padding-right: calc(var(--bs-gutter-x)* .5);
                    }
                }

                .ce-bodytext {
                    @extend .col-lg-6;
                    @extend .col-xl-5;

                    @include media-breakpoint-up(lg) {
                        z-index: 1;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translate(90%, -50%);
                    }
                    @include media-breakpoint-up(xl) {
                        transform: translate(100%, -50%);
                    }
                }
            }

            &.ce-right {
                .ce-gallery {
                    @extend .col-lg-6;
                    @extend .offset-lg-6;
                    @extend .order-lg-2;
                }

                .ce-bodytext {
                    @extend .offset-lg-2;
                    @extend .col-lg-6;
                    @extend .col-xl-5;
                    @extend .order-lg-1;

                    @include media-breakpoint-up(lg) {
                        z-index: 1;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translate(-90%, -50%);
                    }
                    @include media-breakpoint-up(xl) {
                        transform: translate(-100%, -50%);
                    }
                }
            }
        }
    }
}

.frame-type-menu_subpages,
.frame-type-menu_pages,
.frame-type-menu_section {
    margin-bottom: 2rem;

    &.frame-layout-0 {
        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                a {
                    position: relative;
                    display: block;
                    color: $body-color;
                    padding: 0.5rem 0;
                    margin: 0.5rem 0;
                    border-bottom: 1px solid $gray-light;

                    &::after {
                        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                        font-family: 'iconkuoni';
                        content: '\e911';
                        position: absolute;
                        right: 0;
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }

    &.frame-layout-1 {
        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                display: inline-block;

                a {
                    @extend .btn;
                    --#{$prefix}btn-font-size: 1.25rem;
                    --#{$prefix}btn-bg: #{$yellow-light};
                    --#{$prefix}btn-hover-bg: #{$yellow-light};
                    --#{$prefix}btn-active-bg: #{$yellow-light};
                    --#{$prefix}btn-border-radius: 8px;
                    --#{$prefix}btn-padding-x: 0;
                    --#{$prefix}btn-padding-y: 0;
                    margin: 0 0.5rem 0.75rem 0;
                    line-height: 1;
                    display: flex;
                    span {
                        padding: 0.6rem 1rem 0.6rem;
                        display: flex;
                        text-align: left;
                        align-items: center;
                        &.icon {
                            padding: 0.6rem 0.5rem 0.6rem;
                            background-color: $yellow;
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                            i {
                                font-size: 1.3rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.frame-type-menu_section {
    &.frame-layout-0 {
        ul {
            li {
                a {
                    &::after {
                        content: '\e912';
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}