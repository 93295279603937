@import '@splidejs/splide/src/css/template/default';
@import '@splidejs/splide-extension-video/src/css';

.embed-responsive {
    &.embed-responsive-16by9 {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
    }

    &.no-consent {
        background-color: $gray-light;

        .no-consent-info {
            display: block;
            font-style: italic;
            text-align: center;
            padding: 15rem;
            margin: 0;
            a {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

video {
    width: 100%;
    height: auto;
}
