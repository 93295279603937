.social-icons {
    margin: 1rem 0 2rem;

    @include media-breakpoint-up(md) {
        margin: 1rem 0;
    }

    .circle-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border: 1px solid $white;
        border-radius: 50%;
        padding-top: 2px;

        i {
            font-size: 1.7rem;
            line-height: 1;
        }
    }

    .social-icons-link {
        margin-right: 0.75rem;
        display: inline-block;

        /*
        &:hover,
        &:active,
        &:focus {
            .circle-wrap {
                border: 1px solid $primary;

                i {
                    color: $primary;
                }
            }
        }
        */
    }
}
