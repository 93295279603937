// ---
// Custom colors and variables

$primary-light:   #F1655D; // Grapefruit
$secondary-light: #6aa6c5; // Pool Blue
$yellow-light:    #FFCD66; // Mango Mochi
$green-light:     #B7C898; // Matcha Ice

$highlight:      #ddd3b7;  // Sandy Beach

$gray-darker:    #636360;
$gray-dark:      #aaaaaa;
$gray-light:     #D2D2CA;
$gray-lighter:   #f1f1f3;

$body-bg-gray:      #f8f8f8; // used for body tag and accordion-bg variable
$color-booking:     #ffffff; // Fastbooking
$border-radius-big: 12px;

$card-focus-box-shadow: 0 0 0 0.25rem rgba(189, 30, 36, 0.25);
$accordion-focus-box-shadow: none;

// ---
// Default bootstrap variables

// Color system (6)
$white:    #fff;
$gray-100: $gray-lighter;
$gray-200: $gray-lighter;
$gray-300: $gray-light;
$gray-400: $gray-light;
$gray-500: $gray-dark;
$gray-600: $gray-dark;
$gray-700: $gray-dark;
$gray-800: $gray-darker;
$gray-900: $gray-darker;
$black:    #212121;

$blue:    #6AA6C5; // Pool Blue
$purple:  #81315B; // Grape
$red:     #BD1E24; // Very Cherry
$orange:  #EB741F; // Juicy Orange
$yellow:  #FCB215; // Lemonade
$green:   #496323; // Palm Green
$teal:    #008178; // Dreamy Ocean

$colors: (
    "blue":       $blue,
    "purple":     $purple,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "black":      $black,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
);

$primary:        #bd1e24; // Primary
$secondary:      #346485; // Blueberry smoothie

$enable-dark-mode: false;
//$color-mode-type: media-query;

// Body (433)
$body-color:     $black;
$body-bg:        #ffffff;

// Links (449)
$link-color:       $primary;
$link-decoration:  none;
//$link-shade-percentage:                   20% !default;
//$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;

// Components (528)
$border-radius: 8px;
$border-color: $gray-dark;

// Typography (600)
$font-family-sans-serif: 'Noto Sans', sans-serif;

$font-size-root: 16px;
$font-size-base: 1.126rem;

$font-weight-light:           400;
$font-weight-normal:          400;
$font-weight-medium:          400;
$font-weight-semibold:        700;
$font-weight-bold:            700;

$h1-font-size:                $font-size-root * 2.625; // 42px
$h2-font-size:                $font-size-root * 2.125; // 34px
$h3-font-size:                $font-size-root * 1.875; // 30px
$h4-font-size:                $font-size-root * 1.625; // 26px
$h5-font-size:                $font-size-root * 1.375; // 22px

$headings-font-family:        'Clarendon', serif;
$headings-font-weight:        700;
$headings-line-height:        1.2;

// Tables (726)
$table-th-font-weight: $font-weight-bold;
$table-striped-bg:     $gray-lighter;
$table-border-color:   $gray-light;

// Buttons + Forms (784)
//$input-btn-padding-y:         0.375rem;
//$input-btn-padding-x:         0.75rem;
$input-btn-padding-y-lg:      0.75rem;
$input-btn-padding-x-lg:      1.5rem;

// Buttons (813)
$btn-padding-y:               0.75rem;
$btn-padding-x:               1.5rem;
$btn-border-radius:           $border-radius;
$btn-border-radius-sm:        $border-radius;
$btn-border-radius-lg:        $border-radius;

// Forms (866)
$form-label-font-weight:      400;
$input-border-color:          $gray-dark;
$input-border-radius:         $border-radius;

// Pagination (1289)
$pagination-padding-y:              0;
$pagination-padding-x:              0;
$pagination-font-size:              .875rem;
$pagination-color:                  $body-color;
$pagination-border-radius:          50%;
$pagination-border-color:           $gray-light;
$pagination-active-bg:              $secondary;
$pagination-active-border-color:    $secondary;

// Cards (1339)
$card-spacer-y:         1.5rem;
$card-spacer-x:         1.5rem;
$card-border-width:     0;
$card-border-color:     $gray-light;
$card-cap-bg:           #fff;
$card-bg:               #fff;

// Accordion (1363)
$accordion-bg:                      $body-bg-gray;
$accordion-border-width:            0;
//$accordion-border-color:            #aaaaaa;
$accordion-body-padding-x:          0;
$accordion-body-padding-y:          0.25rem;
$accordion-button-padding-x:        0;
$accordion-button-active-bg:        $body-bg;
$accordion-button-active-color:     $body-color;
$accordion-button-focus-box-shadow: $accordion-focus-box-shadow;

// Breadcrumbs (1629)
$breadcrumb-divider-color:   $body-color;
$breadcrumb-active-color:    $body-color;
$breadcrumb-divider:         "\e911";

