.promobox {

    @include media-breakpoint-up(sm) {
        padding: 2.25rem;
        background-color: $white;
    }

    .promobox-content {
        background-color: $black;
        color: $white;
        padding: 2.25rem 2.25rem;

        p {
            &.lead {
                line-height: 1.3
            }

            &.promoprice {
                font-family: $headings-font-family;
                font-size: 4rem;

                span.currency {
                    font-family: $font-family-sans-serif;
                    font-size: 1.375rem;
                }
            }
        }

        @include media-breakpoint-up(md) {
            background-color: transparent;
            color: $black;
            padding: 0;
        }
    }

    .promobox-code {
        background-color: $green-light;

        p.label {
            margin-bottom: 0.5rem;
        }

        .roundel {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2.25rem 2.25rem;
        }

        .promocode {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .code {
                display: inline-block;
                color: $white;
                background-color: $green;
                padding: 1rem 1.5rem;
                margin-left: 0.25rem;
                margin-right: 0.25rem;
                border: 1px solid $green;
                border-radius: $border-radius;
            }
            a {
                color: $green;
                &:hover, &:active, &:focus {
                    color: $white;
                }
            }
        }

        @include media-breakpoint-up(md) {
            background-color: transparent;

            .roundel {
                aspect-ratio: 1 / 1;
                width: 100%;
                max-width: 300px;
                color: $green;
                background-color: $green-light;
                border-radius: 50%;
                padding: 0;
            }
        }
    }
}

.code-highlight {
    animation: target-fade 1.5s ease-in-out 1;
}

@keyframes target-fade {
    0% {
        background-color: rgba(255,255,255,0.25);
    }

    100% {
        background-color: rgba(73,99,35,1);
    }
}